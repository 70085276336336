import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/css/font.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Keyword from "./component/Keyword";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="App">
        <Routes>
          <Route path={"/"} element={<Keyword />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

