import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/footer.css";

export default function Footer() {
  return (
    <Card className="text-center footer">
      <Card.Body>
        <Card.Title>SYNC-KEYWROD</Card.Title>
        <Button variant="primary">협업문의</Button>
      </Card.Body>
    </Card>
  );
}
