import React, { useState, useEffect } from "react";
import { Container, Table, Form, Button } from "react-bootstrap";
import axios from "axios";
import he from "he";
import XMLParser from "react-xml-parser";
import keywordImg from "../img/onekeyword.png";
import naverlogo from "../img/NAVERLogo_Green.png";
import googlelogo from "../img/google_logo.png";
import youtubelogo from "../img/yt_logo_rgb_light.png";
import tictoklogo from "../img/TikTok-logo.png";
import "../css/keyword.css";
import RealKeyword from "./RealKeyword";

function Keyword() {
  const [query, setQuery] = useState("");
  const [naverKeywords, setNaverKeywords] = useState([]);
  const [googleKeywords, setGoogleKeywords] = useState([]);
  const [youtubeKeywords, setYoutubeKeywords] = useState([]);
  const [tiktokKeywords, setTiktokKeywords] = useState([]);

  const fetchKeywords = async () => {
    try {
      const [naverRes, googleRes, youtubeRes, tiktokRes] = await Promise.all([
        axios.get(
          `https://www.synckeyword.com:3001/naverKeyword?query=${encodeURIComponent(
            query
          )}`
        ),
        axios.get(
          `https://www.synckeyword.com:3001/googleKeyword?query=${encodeURIComponent(
            query
          )}`
        ),
        axios.get(
          `https://www.synckeyword.com:3001/youtubeKeyword?query=${encodeURIComponent(
            query
          )}`
        ),
        axios.get(
          `https://www.synckeyword.com:3001/tiktokKeyword?query=${encodeURIComponent(
            query
          )}`
        ),
      ]);

      const naverKeywords = naverRes.data.items[0].map((item) => item[0]);
      const decodedGoogle = he.decode(googleRes.data);
      const parsed = new XMLParser().parseFromString(decodedGoogle);
      const googleKeywords = parsed.children.map(
        (child) => child.children[0].attributes.data
      );
      const youtubeKeywords = youtubeRes.data[1].map((item) => item[0]);
      const tiktokKeywords = tiktokRes.data.map((item) => item.content);

      setNaverKeywords(naverKeywords);
      setGoogleKeywords(googleKeywords);
      setYoutubeKeywords(youtubeKeywords);
      setTiktokKeywords(tiktokKeywords);
    } catch (error) {
      console.error("Error fetching keywords:", error);
    }
  };

  return (
    <Container style={{ marginTop: "3%", height: "1200px" }}>
      <img
        src={keywordImg}
        className="mx-auto"
        style={{ width: "65%" }}
        alt="키워드 이미지"
      />
      <Form.Control
        size="lg"
        type="text"
        placeholder="검색어를 입력하세요"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && fetchKeywords()}
        className="mx-auto"
        style={{
          borderRadius: "30px",
          textAlign: "center",
          width: "65%",
          margin: "0 auto",
          borderColor: "#2b8c9b",
          borderWidth: "4px",
          marginTop: "5%",
        }}
      />
      <br />
      <br />
      <Table
        striped
        bordered
        hover
        className="mx-auto"
        style={{
          boxShadow: "0 0 20px rgba(43, 140, 155, 0.8)",
          borderRadius: "20px",
          overflow: "hidden",
          marginTop: "3%",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "8%" }}>NO.</th>
            <th style={{ width: "20%", verticalAlign: "middle" }}>
              <img src={naverlogo} style={{ width: "4em" }} alt="네이버 로고" />
            </th>
            <th style={{ width: "20%", verticalAlign: "middle" }}>
              <img src={googlelogo} style={{ width: "4em" }} alt="구글 로고" />
            </th>
            <th style={{ width: "20%", verticalAlign: "middle" }}>
              <img
                src={youtubelogo}
                style={{ width: "4em" }}
                alt="유튜브 텍스트"
              />
            </th>
            <th style={{ width: "20%", verticalAlign: "middle" }}>
              <img
                src={tictoklogo}
                style={{ width: "4em" }}
                alt="틱톡 텍스트"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({
            length: Math.max(
              naverKeywords.length,
              googleKeywords.length,
              youtubeKeywords.length,
              tiktokKeywords.length
            ),
          }).map((_, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {naverKeywords[index] ? (
                  <a
                    href={`https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=${encodeURIComponent(
                      naverKeywords[index]
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {naverKeywords[index]}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {googleKeywords[index] ? (
                  <a
                    href={`https://www.google.com/search?q=${encodeURIComponent(
                      googleKeywords[index]
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {googleKeywords[index]}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {youtubeKeywords[index] ? (
                  <a
                    href={`https://www.youtube.com/results?search_query=${encodeURIComponent(
                      youtubeKeywords[index]
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {youtubeKeywords[index]}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {tiktokKeywords[index] ? (
                  <a
                    href={`https://www.tiktok.com/search?q=${encodeURIComponent(
                      tiktokKeywords[index]
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {tiktokKeywords[index]}
                  </a>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
      <br />
      <h3>플랫폼 실시간 검색어</h3>
      <RealKeyword />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}

export default Keyword;
