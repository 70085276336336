import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import naverlogo from "../img/NAVERLogo_Green.png";
import googlelogo from "../img/google_logo.png";
import natelogo from "../img/Nate_2009_logo.png";

function RealKeyword() {
  const [signalRealKeywords, setSignalRealKeywords] = useState([]);
  const [googleRealKeywords, setGoogleRealKeywords] = useState([]);
  const [nateRealKeywords, setNateRealKeywords] = useState([]);

  useEffect(() => {
    const fetchRealKeywords = async () => {
      try {
        const [signalRealRes, googleRealRes, nateRealRes] = await Promise.all([
          axios.get(`https://www.synckeyword.com:3001/signal-keyword`),
          axios.get(`https://www.synckeyword.com:3001/google-keyword`),
          axios.get(`https://www.synckeyword.com:3001/nate-keyword`),
        ]);

        await setSignalRealKeywords(signalRealRes.data);
        await setGoogleRealKeywords(googleRealRes.data);
        await setNateRealKeywords(nateRealRes.data);

        console.log(signalRealKeywords);
        console.log(googleRealKeywords);
        console.log(nateRealKeywords);
      } catch (error) {
        console.error("Error fetching real keywords:", error);
      }
    };

    fetchRealKeywords();
  }, []);

  return (
    <Table
      striped
      bordered
      hover
      className="mx-auto"
      style={{
        boxShadow: "0 0 20px rgba(43, 140, 155, 0.8)",
        borderRadius: "20px",
        overflow: "hidden",
        marginTop: "3%",
        width: "100%",
      }}
    >
      <thead>
        <tr>
          <th style={{ width: "10%" }}>NO.</th>
          <th style={{ width: "30%", verticalAlign: "middle" }}>
            <img src={naverlogo} style={{ width: "4em" }} alt="네이버 로고" />
          </th>
          <th style={{ width: "30%", verticalAlign: "middle" }}>
            <img src={googlelogo} style={{ width: "4em" }} alt="구글 로고" />
          </th>
          <th style={{ width: "30%", verticalAlign: "middle" }}>
            <img src={natelogo} style={{ width: "4em" }} alt="네이트 로고" />
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.from({
          length: Math.max(
            signalRealKeywords.length,
            googleRealKeywords.length,
            nateRealKeywords.length
          ),
        }).map((_, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              {signalRealKeywords[index] ? (
                <a
                  href={`https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=${encodeURIComponent(
                    signalRealKeywords[index].keyword_title
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {signalRealKeywords[index].keyword_title}
                </a>
              ) : (
                "-"
              )}
            </td>
            <td>
              {googleRealKeywords[index] ? (
                <a
                  href={`https://www.google.com/search?q=${encodeURIComponent(
                    googleRealKeywords[index].title
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {googleRealKeywords[index].title}
                </a>
              ) : (
                "-"
              )}
            </td>
            <td>
              {nateRealKeywords[index] ? (
                <a
                  href={`https://search.daum.net/nate?w=tot&DA=NBZ&q=${encodeURIComponent(
                    nateRealKeywords[index].title
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {nateRealKeywords[index].title}
                </a>
              ) : (
                "-"
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default RealKeyword;
