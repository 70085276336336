import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import synclogo from "../img/sync_logo.png";

export default function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <a href="https://www.synckeyword.com/">
            <img
              src={synclogo}
              alt="Sync Logo"
              style={{ width: "100px", height: "auto" }}
            />
          </a>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
